<template>
  <div class="crown__datenschutz">
    <div id="bg">
      <img :src="img" alt="" />
    </div>
    <Menu />

    <div class="crown__datenschutz--block-aaa">
      <div class="crown__datenschutz--block-header">
        <div class="crown__house--block">
          <div class="crown__house--side">
            <div class="crown__house--side-home">
              <div class="crown__house--side-homename">strandkai</div>
              <div class="crown__house--side-homepage">
                <router-link class="menu__logo" to="/">
                  Datenschutz &#9660;
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="crown__house--block-bottom"></div>
      <div class="outerContainerDate">
        <div class="container">
          <p style="text-align: center">Datenschutz:</p>
          <p style="text-align: center">
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und
            Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz
            „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
            verbundenen Webseiten, Funktionen und Inhalte sowie externen
            Onlinepräsenzen, wie z.B. unser Social Media Profile auf.
            (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick
            auf die verwendeten Begrifflichkeiten, wie z.B. „personenbezogene
            Daten“ oder deren „Verarbeitung“ verweisen wir auf die Definitionen
            im Art. 4 der Datenschutzgrundverordnung (DSGVO).
          </p>
          <p style="text-align: center">
            Verantwortlicher:<br />
            DC DEVELOPMENTS GMBH & CO. KG<br />
            Großer Grasbrook 9<br />
            D-20457 Hamburg
          </p>
          <p style="text-align: center">
            Handelsregister Hamburg HRB 139679 Sitz der Gesellschaft: Hamburg
          </p>
          <p style="text-align: center">
            Komplementärin: DC Developments Management GmbH<br />
            Geschäftsführer: Björn Dahler, Karsten Nagel, Lothar
            Schubert
          </p>
          <p style="text-align: center">
            Telefon +49 (0)40 60 00 37-200<br />
            E-Mail: info@dcdevelopments.de
          </p>
          <p style="text-align: center">
            Datenschutzbeauftragter:<br />
            Eric Drissler<br />
            ED Computer & Design GmbH & Co. KG<br />
            Lina-Bommer-Weg 4<br />
            51149 Köln<br />
            Telefon +49 (0) 221 28887766<br />
            E-Mail info@edcud.de
          </p>
          <div class="mouse">
            <img src="./../assets/mouse.png" alt="">
          </div>
          <p align="justify">
            Arten der verarbeiteten Daten:<br />
            Kontaktdaten (z.B., E-Mail).<br />
            Inhaltsdaten (z.B., Fotografien, Videos).<br />
            Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
            IP-Adressen).<br />
            Verarbeitung besonderer Kategorien von Daten (Art. 9 Abs. 1
            DSGVO):<br />
            Es werden keine besonderen Kategorien von Daten verarbeitet.<br />
            Kategorien der von der Verarbeitung betroffenen Personen:<br />
            Besucher und Nutzer des Onlineangebotes.<br />
            Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend
            auch als „Nutzer“.
          </p>
          <p align="justify">
            Zweck der Verarbeitung:<br />
            Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
            Marketing, Werbung und Marktforschung.<br />
            Sicherheitsmaßnahmen.<br />
            Stand des Dokuments: 24.05.2018
          </p>
          <p align="justify">
            1. Maßgebliche Rechtsgrundlagen<br />
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
            Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
            Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
            1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
            zur Erfüllung unserer Leistungen und Durchführung vertraglicher
            Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b
            DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
            unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
            und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
          </p>
          <p align="justify">
            2. Änderungen und Aktualisierungen der Datenschutzerklärung<br />
            Wir bitten Sie sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p align="justify">
            3. Sicherheitsmaßnahmen<br />
            3.1.Wir treffen nach Maßgabe des Art. 32 DSGVO unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit
            und Schwere des Risikos für die Rechte und Freiheiten natürlicher
            Personen, geeignete technische und organisatorische Maßnahmen, um
            ein dem Risiko angemessenes Schutzniveau zu gewährleisten; Zu den
            Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
            Integrität und Verfügbarkeit von Daten durch Kontrolle des
            physischen Zugangs zu den Daten, als auch des sie betreffenden
            Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit
            und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet,
            die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und
            Reaktion auf Gefährdung der Daten gewährleisten. Ferner
            berücksichtigen wir den Schutz personenbezogener Daten bereits bei
            der Entwicklung, bzw. Auswahl von Hardware, Software sowie
            Verfahren, entsprechend dem Prinzip des Datenschutzes durch
            Technikgestaltung und durch datenschutzfreundliche Voreinstellungen
            berücksichtigt (Art. 25 DSGVO). 3.2.Zu den Sicherheitsmaßnahmen
            gehört insbesondere die verschlüsselte Übertragung von Daten
            zwischen Ihrem Browser und unserem Server.
          </p>
          <p align="justify">
            4. Zusammenarbeit mit Auftragsverarbeitern und Dritten<br />
            4.1.Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber
            anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
            offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
            die Daten gewähren, erfolgt dies nur auf Grundlage einer
            gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
            Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
            DSGVO zur Vertragserfüllung erforderlich ist),Sie eingewilligt
            haben, eine rechtliche Verpflichtung dies vorsieht oder auf
            Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
            Beauftragten, Webhostern, etc.).<br />
            4.2.Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
            eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht
            dies auf Grundlage des Art. 28 DSGVO.
          </p>
          <p align="justify">
            5. Übermittlungen in Drittländer Sofern wir Daten in einem Drittland
            (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
            Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der
            Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw.
            Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn
            es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage
            Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder
            auf Grundlage unserer berechtigten Interessen geschieht.
            Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse,
            verarbeiten oder lassen wir die Daten in einem Drittland nur beim
            Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
            verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage
            besonderer Garantien, wie der offiziell anerkannten Feststellung
            eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA
            durch das „Privacy Shield“) oder Beachtung offiziell anerkannter
            spezieller vertraglicher Verpflichtungen (so genannte
            „Standardvertragsklauseln“).
          </p>
          <p align="justify">6. Rechte der betroffenen Personen<br /></p>
          <p align="justify">
            6.1.Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend Art. 15 DSGVO.<br />
            6.2.Sie haben entsprechend. Art. 16 DSGVO das Recht, die
            Vervollständigung der Sie betreffenden Daten oder die Berichtigung
            der Sie betreffenden unrichtigen Daten zu verlangen.<br />
            6.3.Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen,
            dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
            nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung
            der Daten zu verlangen.<br />
            6.4.Sie haben das Recht zu verlangen, dass die Sie betreffenden
            Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20
            DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche
            zu fordern. 6.5.Sie haben ferner gem. Art. 77 DSGVO das Recht, eine
            Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
          </p>
          <p align="justify">
            7. Widerrufsrecht Sie haben das Recht, erteilte Einwilligungen gem.
            Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.
          </p>
          <p align="justify">
            8. Widerspruchsrecht Sie können der künftigen Verarbeitung der Sie
            betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
            widersprechen. Der Widerspruch kann insbesondere gegen die
            Verarbeitung für Zwecke der Direktwerbung erfolgen.
          </p>
          <p align="justify">
            9. Cookies und Widerspruchsrecht bei Direktwerbung Wir setzen
            temporäre und permanente Cookies, d.h. kleine Dateien, die auf den
            Geräten der Nutzer gespeichert werden ein (Erklärung des Begriffs
            und der Funktion, siehe letzter Abschnitt dieser
            Datenschutzerklärung). Zum Teil dienen die Cookies der Sicherheit
            oder sind zum Betrieb unseres Onlineangebotes erforderlich (z.B.,
            für die Darstellung der Website) oder um die Nutzerentscheidung bei
            der Bestätigung des Cookie-Banners zu speichern. Daneben setzen wir
            oder unsere Technologiepartner Cookies zur Reichweitenmessung und
            Marketingzwecken ein, worüber die Nutzer im Laufe der
            Datenschutzerklärung informiert werden. Ein genereller Widerspruch
            gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten
            Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des
            Trackings, über die US-amerikanische Seite
            http://www.aboutads.info/choices/ oder die EU-Seite
            http://www.youronlinechoices.com/ erklärt werden. Des Weiteren kann
            die Speicherung von Cookies mittels deren Abschaltung in den
            Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass
            dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
            genutzt werden können.
          </p>
          <p align="justify">
            10. Löschung von Daten<br />
            10.1.Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17
            und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
            Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich
            angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald
            sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der
            Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
            Sofern die Daten nicht gelöscht werden, weil sie für andere und
            gesetzlich zulässige Zwecke erforderlich sind, wird deren
            Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht
            für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
            handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.<br />
            10.2.Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung
            insbesondere für 6 Jahre gemäß § 257 Abs. 1 HGB (Handelsbücher,
            Inventare, Eröffnungsbilanzen, Jahresabschlüsse, Handelsbriefe,
            Buchungsbelege, etc.) sowie für 10 Jahre gemäß § 147 Abs. 1 AO
            (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handels- und
            Geschäftsbriefe, für Besteuerung relevante Unterlagen, etc.).
          </p>
          <p align="justify">
            11. Erbringung vertraglicher Leistungen<br />
            11.1.Im Rahmen der Inanspruchnahme unserer Onlinedienstes, speichern
            wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung.
            Die Speicherung erfolgt auf Grundlage unserer berechtigten
            Interessen, als auch der Nutzer an Schutz vor Missbrauch und
            sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte
            erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
            Ansprüche erforderlich oder es besteht hierzu eine gesetzliche
            Verpflichtung gem. Art. 6 Abs. 1 lit. c DSGVO.<br />
            11.2.Die Löschung erfolgt nach Ablauf gesetzlicher Gewährleistungs-
            und vergleichbarer Pflichten, die Erforderlichkeit der Aufbewahrung
            der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
            Archivierungspflichten erfolgt die Löschung nach deren Ablauf (Ende
            handelsrechtlicher (6 Jahre) und steuerrechtlicher (10 Jahre)
            Aufbewahrungspflicht); Angaben im Kundenkonto verbleiben bis zu
            dessen Löschung.
          </p>
          <p align="justify">
            12. Kontaktaufnahme<br />
            12.1.Bei der Kontaktaufnahme mit uns (per Kontaktformular oder
            E-Mail) werden die Angaben des Nutzers zur Bearbeitung der
            Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO
            verarbeitet.<br />
            12.2.Die Angaben der Nutzer können in unserem
            Customer-Relationship-Management System ("CRM System") oder
            vergleichbarer Anfragenorganisation gespeichert werden.<br />
            12.3.Wir löschen die Anfragen, sofern diese nicht mehr erforderlich
            sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Anfragen
            von Kunden, die über ein Kundenkonto verfügen, speichern wir
            dauerhaft und verweisen zur Löschung auf die Angaben zum
            Kundenkonto. Im Fall der gesetzlichen Archivierungspflichten erfolgt
            die Löschung nach deren Ablauf (Ende handelsrechtlicher (6 Jahre)
            und steuerrechtlicher (10 Jahre) Aufbewahrungspflicht).
          </p>
          <p align="justify">
            13. Erhebung von Zugriffsdaten und Logfiles<br />
            13.1.Wir erheben auf Grundlage unserer berechtigten Interessen im
            Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf
            den Server, auf dem sich dieser Dienst befindet (sogenannte
            Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
            Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
            Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
            Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
            besuchte Seite),IP-Adresse und der anfragende Provider.<br />
            13.2.Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal sieben Tagen gespeichert und danach gelöscht. Daten,
            deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind
            bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
          </p>
          <p align="justify">
            14. Onlinepräsenzen in sozialen Medien<br />
            14.1.Wir unterhalten auf Grundlage unserer berechtigten Interessen
            im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Onlinepräsenzen innerhalb
            sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden,
            Interessenten und Nutzern kommunizieren und sie dort über unsere
            Leistungen informieren zu können. Beim Aufruf der jeweiligen
            Netzwerke und Plattformen gelten die Geschäftsbedingungen und die
            Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.<br />
            14.2.Soweit nicht anders im Rahmen unserer Datenschutzerklärung
            angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit uns
            innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B.
            Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten
            zusenden.
          </p>
          <p align="justify">
            15. Cookies & Reichweitenmessung<br />
            15.1.Cookies sind Informationen, die von unserem Webserver oder
            Webservern Dritter an die Web-Browser der Nutzer übertragen und dort
            für einen späteren Abruf gespeichert werden. Bei Cookies kann es
            sich um kleine Dateien oder sonstige Arten der
            Informationsspeicherung handeln.<br />
            15.2.Über den Einsatz von Cookies im Rahmen pseudonymer
            Reichweitenmessung werden die Nutzer im Rahmen dieser
            Datenschutzerklärung informiert.<br />
            15.3.Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner
            gespeichert werden, werden sie gebeten die entsprechende Option in
            den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
            Cookies können in den Systemeinstellungen des Browsers gelöscht
            werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen
            dieses Onlineangebotes führen.<br />
            15.4.Sie können dem Einsatz von Cookies, die der Reichweitenmessung
            und Werbezwecken dienen, über die Deaktivierungsseite der
            Netzwerkwerbeinitiative (http://optout.networkadvertising.org/) und
            zusätzlich die US-amerikanische Webseite
            (http://www.aboutads.info/choices) oder die europäische Webseite
            (http://www.youronlinechoices.com/uk/your-ad-choices/)
            widersprechen.
          </p>
          <p align="justify">
            16. Google Analytics<br />
            16.1.Wir setzen auf Grundlage unserer berechtigten Interessen (d.h.
            Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
            unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO)
            Google Analytics, einen Webanalysedienst der Google LLC („Google“)
            ein. Google verwendet Cookies. Die durch das Cookie erzeugten
            Informationen über Benutzung des Onlineangebotes durch die Nutzer
            werden in der Regel an einen Server von Google in den USA übertragen
            und dort gespeichert.<br />
            16.2.Google ist unter dem Privacy-Shield-Abkommen zertifiziert und
            bietet hierdurch eine Garantie, das europäische Datenschutzrecht
            einzuhalten
            (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).<br />
            16.3.Google wird diese Informationen in unserem Auftrag benutzen, um
            die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um
            Reports über die Aktivitäten innerhalb dieses Onlineangebotes
            zusammenzustellen und um weitere, mit der Nutzung dieses
            Onlineangebotes und der Internetnutzung verbundene Dienstleistungen,
            uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten
            pseudonyme Nutzungsprofile der Nutzer erstellt werden.<br />
            16.4.Wir setzen Google Analytics nur mit aktivierter
            IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird
            von Google innerhalb von Mitgliedstaaten der Europäischen Union oder
            in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
            IP-Adresse an einen Server von Google in den USA übertragen und dort
            gekürzt.<br />
            16.5.Die von dem Browser des Nutzers übermittelte IP-Adresse wird
            nicht mit anderen Daten von Google zusammengeführt. Die Nutzer
            können die Speicherung der Cookies durch eine entsprechende
            Einstellung ihrer Browser-Software verhindern; die Nutzer können
            darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
            ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die
            Verarbeitung dieser Daten durch Google verhindern, indem sie das
            unter folgendem Link verfügbare Browser-Plugin herunterladen und
            installieren: https://tools.google.com/dlpage/gaoptout?hl=de.<br />
            16.6.Weitere Informationen zur Datennutzung durch Google,
            Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf den
            Webseiten von Google:
            https://www.google.com/intl/de/policies/privacy/partners
            („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps
            unserer Partner“),https://policies.google.com/technologies/ads
            („Datennutzung zu
            Werbezwecken“),https://adssettings.google.com/authenticated
            („Informationen verwalten, die Google verwendet, um Ihnen Werbung
            einzublenden“).<br />
            16.7.Im Übrigen werden die personenbezogenen Daten nach einem Ablauf
            von 14 Monaten anonymisiert oder gelöscht
          </p>
          <p align="justify">
            17. Einbindung von Diensten und Inhalten Dritter<br />
            17.1.Wir setzen innerhalb unseres Onlineangebotes auf Grundlage
            unserer berechtigten Interessen (d.h. Interesse an der Analyse,
            Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im
            Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote
            von Drittanbietern ein, um deren Inhalte und Services, wie z.B.
            Videos oder Schriftarten einzubinden (nachfolgend einheitlich
            bezeichnet als “Inhalte”). Dies setzt immer voraus, dass die
            Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen,
            da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden
            könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte
            erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren
            jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
            Inhalte verwenden. Drittanbieter können ferner so genannte
            Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet)
            für statistische oder Marketingzwecke verwenden. Durch die
            "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
            Seiten dieser Website ausgewertet werden. Die pseudonymen
            Informationen können ferner in Cookies auf dem Gerät der Nutzer
            gespeichert werden und unter anderem technische Informationen zum
            Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
            weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als
            auch mit solchen Informationen aus anderen Quellen verbunden werden
            können.<br />
            17.2.Die nachfolgende Darstellung bietet eine Übersicht von
            Drittanbietern sowie ihrer Inhalte, nebst Links zu deren
            Datenschutzerklärungen, welche weitere Hinweise zur Verarbeitung von
            Daten und, z.T. bereits hier genannt, Widerspruchsmöglichkeiten
            (sog. Opt-Out) enthalten: Diese Website benutzt "fonts.com", einen
            Schriftarten-Dienst der Monotype GmbH
            ("fonts.com"),Werner-Reimers-Straße 2-4, 61352 Bad Homburg
            ("fonts.com"). Bei jedem Aufruf dieser Website werden zur
            Darstellung der Texte in einer bestimmten Schriftart Dateien von
            einem "fonts.com"-Server geladen. Dabei kann ihre IP-Adresse an
            einen Server von "fonts.com" übertragen werden und im Rahmen des
            üblichen Serverlogs gespeichert werden. Die Weiterverarbeitung
            dieser Informationen obliegt "fonts.com", die entsprechenden
            Bedingungen und Einstellmöglichkeiten entnehmen Sie bitte den
            Datenschutzhinweisen (www.monotype.com/legal/privacy-policy) von
            "fonts.com".
          </p>
          <br />
          <p align="justify">Hinweis</p>
          <p align="justify">
            Bei der in diesem Konfigurator dargestellten Immobilie handelt es
            sich um ein in der Planung bzw. noch im Bau befindliches Objekt.
          </p>
          <p align="justify">
            Alle Angaben, Illustrationen und Visualisierungen wurden mit größter
            Sorgfalt zusammengestellt, dennoch stellen sie einen unverbindlichen
            und vorläufigen Planungsstand dar.
          </p>
          <p align="justify">
            Die konkrete Lage, Ausrichtung und Ausstattung der Wohnungen ist
            ausschließlich der Vertragsdokumentation (Kaufvertrag nebst Anlagen)
            zu entnehmen.
          </p>
          <p align="justify">
            Die in den Plänen und in den Visualisierungen dargestellte
            Dekoration und Möblierung dient lediglich der Veranschaulichung und
            gehört nicht zum Kaufgegenstand.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import imgOst from './../assets/kontakt/back-kontakt.jpg'

export default {
  data: function () {
    return {
      img: imgOst
    };
  },
  name: 'Datenschutz',
  props: {
    msg: String
  },
  components: {
    Menu
  }
}
</script>

<style scoped>
.crown__datenschutz {
  height: 100vh;
}
#bg {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#bg img {
  height: 100vh;
}
.crown__datenschutz--block-header {
  height: 35px;
  width: 1178px;
  background: #fff;
}
.crown__datenschutz--block-aaa {
  position: fixed;
    height: 650px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 4em;
    width: 1178px;
    z-index: 2;
}
.crown__datenschutz--block {
  width: 1178px;
  margin: 0 auto;
  position: relative;
}
.outerContainerDate {
  position: relative;
  height: calc(100% - 35px);
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
}
.container {
  position: relative;
  width: 100%;
  max-width: 935px;
  height: auto;
  display: block;
  margin: auto;
  color: #fff;
  margin-bottom: 4em;
}

.container h2 {
  font-family: "35-FTR", sans-serif;
  font-weight: 600;
}

.crown__house--block {
  position: fixed;
  width: 100%;
}
.crown__house--block-bottom {
  position: fixed;
  width: 1178px;
  bottom: 2em;
  z-index: 2;
  background: #fff;
  height: 35px;
}
.crown__house--side .crown__house--side-home {
  display: flex;
  flex-wrap: wrap;
  width: 1178px;
  justify-content: space-between;
  font-family: "35-FTR", sans-serif;
  font-size: 15px;
  letter-spacing: 0.06rem;
  box-sizing: border-box;
  height: 35px;
  align-items: center;
    justify-content: space-between;
}

.crown__house--side-homename {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
}
.crown__house--side-homepage {
  padding-right: 2em;
}
.crown__house--side-homepage a {
  color: #323a41;
  font-weight: 300;
}

.mouse {
  display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 2em;
    padding-bottom: 2em;
}
</style>
