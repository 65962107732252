<template>
  <div class="rundgang">
    <Menu class="menu" />
    <div class="rundgang__scene">
      <a-scene>
        <a-sky :src="imgActive" rotation="0 -20 0" crossorigin="anonymous">
          <a-animation
            attribute="rotation"
            fill="forwards"
            easing="linear"
            dur="50000"
            from="0 0 0"
            to="0 360 0"
            repeat="indefinite"
          ></a-animation>
        </a-sky>
      </a-scene>
    </div>
    <div v-if="visible == true" class="rundgang--navigation-table">
      <div class="table__head">
        <div class="table__head--name">360° Rundgang</div>
        <div v-on:click="visible = false" class="table__head--button">
          Navigation &#9660;
        </div>
      </div>
      <section>
        <header class="header-padding">
          <div
            class="header-padding--block"
            v-for="(h, index) in hover"
            :key="index"
          >
            <div
              class="col"
              @mouseover="hover[index] = true"
              @mouseleave="hover[index] = false"
              v-on:click="imgActive = img[index]"
            >
              <img
                v-if="!hover[index]"
                class="img-pin"
                src="./../assets/pin.png"
                alt=""
              />
              <img v-if="hover[index]" src="./../assets/pin-hover.svg" alt="" />
              <p>{{ clean(nameIMG[index]) }}</p>
            </div>
          </div>
        </header>
      </section>
    </div>
    <div v-if="visible == false" class="rundgang--navigation-table">
      <div class="none-padding"></div>
      <div class="table__head">
        <div class="table__head--name">360° Rundgang</div>
        <div v-on:click="visible = true" class="table__head--button">
          Navigation &#9650;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './../components/Menu'
import axios from 'axios'

export default {
  data: function () {
    return {
      visible: true,
      hover: {},
      img: {},
      nameIMG: {},
      imgActive: ''
    };
  },
  components: {
    Menu
  },
  methods: {
    clean(str) {
      let s = str.replace(',', '')
      s = s.replace(',', '')
      s = s.replace(/^.{2}/, '')
      return s
    }
  },
  mounted() {
    axios.get('https://cadman-63dc7.firebaseio.com/Houses/f336c1a5-8cf2-4141-9509-51ab08eddfb0/Data/TourData/.json')
      .then((response) => {
        this.img360 = response.data
      })
      .finally(() => {
        let i = 0
        this.hover, this.img, this.nameIMG = {}

        for (let key in this.img360) {

          this.hover[i] = false
          this.img[i] = this.img360[key]
          this.nameIMG[i] = key
          i++

        }

        this.imgActive = this.img[0]
      })
  }
}
</script>

<style scoped>
.rundgang .menu {
  z-index: 1;
  position: fixed;
  width: 100%;
}
.rundgang {
  width: 100%;
  text-align: center;
}
.rundgang--navigation-menu {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.rundgang--navigation-menu > div {
  padding: 0.5em;
}
.rundgang--footer {
  position: fixed;
  height: 150px;
  bottom: 0;
  width: 100vw;
  background: rgb(50, 58, 65);
  background: linear-gradient(
    180deg,
    rgba(50, 58, 65, 1) 0%,
    rgba(50, 58, 65, 0.3) 100%
  );
}
.rundgang--navigation-table {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  font-family: "35-FTR", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  text-decoration: none;
  bottom: 35px;
  z-index: 0;
}
.rundgang--navigation-table section {
  position: relative;
  height: calc(100% - 28px);
  width: 1178px;
  margin: 0 auto;
  background-color: rgba(238, 237, 236, 0.6);
  backdrop-filter: blur(4px);
}
.rundgang--navigation-table .table__head {
  width: 1178px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 35px;
}
.rundgang--navigation-table .table__head--name {
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 2em;
  font-size: 15px;
}
.rundgang--navigation-table .table__head--button {
  padding-right: 2em;
  font-size: 15px;
}
.rundgang--navigation-table header {
  font-weight: bold;
}
.row-padding {
  padding-left: 2em;
  padding-right: 2em;
}
header,
.row {
  display: flex;
  padding: 1em;
  padding-left: 0;
  padding-right: 0;
}
header {
  padding-top: 2em;
  padding-bottom: 2em;
}
.header-padding {
  padding-left: 2em;
  padding-right: 2em;
}
.col {
  flex: 1;
}
.none-padding {
  height: calc(100% - 28px);
  width: 100px;
}
.crown__house--side-homeside button {
  margin: 0;
  text-shadow: 0px 0px;
  padding: 0.5em;
}
a {
  color: black;
}
a:hover {
  background-color: black;
}
.rundgang--house {
  position: relative;
  margin: 0 auto;
  z-index: 2;
}
.rundgang--house-none {
  margin: 0 auto;
  z-index: 2;
}
header.header-padding {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.header-padding--block {
  margin-left: 1em;
  margin-right: 1em;
  display: contents;
}
</style>